<template>
  <div
    v-if="mappedNavigation.length > 1"
    class="breadcrumbs"
    :class="{
      'breadcrumbs--transparent': isTransparent,
      'breadcrumbs--dark-font': isDarkFont
    }"
  >
    <div class="breadcrumbs__wrap">
      <ul class="breadcrumbs__list">
        <li
          v-for="link in mappedNavigation"
          :key="link.title"
          class="breadcrumbs__item"
        >
          <component
            :is="link.spacer ? 'span' : 't3-link'"
            v-if="!link.current"
            :title="null"
            :to="link"
          >
            {{ link.title }}
          </component>

          <span v-else class="breadcrumbs__item--current">
            {{ link.title }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { withBase } from 'ufo'
import { computed } from 'vue'

import { defineBreadcrumb, useSchemaOrg } from '#imports'

import type { BaseBreadcrumbsProps } from '~/types'

const props = defineProps<BaseBreadcrumbsProps>()

const mappedNavigation = computed(() => {
  return props.navigation.map((link, index) => ({
    ...link,
    ...(index === 0 ? { title: 'Home' } : {})
  }))
})

const breadcrumbsSchema = computed(() => {
  if (mappedNavigation.value.length <= 1) return null

  return mappedNavigation.value
    .filter((link) => !link?.spacer)
    .map((link) => ({
      name: link.title,
      item: withBase(link.link, props.baseUrl)
    }))
    .slice(1)
})

useSchemaOrg([
  breadcrumbsSchema.value
    ? defineBreadcrumb({ itemListElement: () => breadcrumbsSchema.value })
    : null
])
</script>

<style lang="scss">
.breadcrumbs {
  padding-block: 0;
  background-color: color(white);

  @include frame-content-container(large);

  &__wrap {
    margin-inline: auto;
    direction: rtl;

    @include media-query(max sm) {
      overflow-x: auto;
    }
  }

  &__list {
    margin-block: 0;
    margin-right: auto;
    list-style: none;
    display: flex;
    padding: spacing(md) 0;
    font-weight: 500;
    direction: ltr;
    width: min-content;
  }

  &__item {
    white-space: nowrap;
    color: hsl(0 0% 0% / 40%);

    &--current {
      font-weight: 600;
      color: color(black);
    }

    & > * {
      padding-block: rem(10px);
    }

    & > a {
      color: inherit;
    }

    & > a:hover {
      color: color(primary);
    }

    &::before {
      content: '/';
      padding: 0 em(12px);
      color: hsl(0 0% 0% / 40%);
    }

    &:first-child::before {
      content: none;
    }
  }

  &--transparent {
    position: absolute;
    top: var(--content-offset, rem(0px));
    width: 100%;
    z-index: z-index(header) - 1;
    background-color: transparent;
  }

  &--transparent &__item {
    color: hsl(0 0% 100% / 80%);

    &--current {
      color: color(white);
    }

    & > a:hover {
      color: color(white);
    }

    &::before {
      color: hsl(0 0% 100% / 80%);
    }
  }

  &--dark-font &__item {
    color: color(black);

    &--current {
      color: color(black);
    }

    & > a:hover {
      color: hsl(0 0% 0% / 60%);
    }

    &::before {
      color: color(black);
    }
  }
}
</style>
